import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {isMobile} from "react-device-detect";

import {tr} from "../../../helpers/translations/tr";

import {authStore} from "../../../store/authStore";

import BlurredBackgroundPopup from '../../Popups/PopupsWrappers/BlurredBackgroundPopup';

import styles from '../policies.module.scss';

const TermsOfConditions = observer(() => {
    const ref = useRef();
    const {setTermsPopup} = authStore;

    useEffect(() => {
        ref.current.innerHTML = tr("terms_and_conditions_full");
        document.querySelector(".openPrivacy").onclick = openPrivacy;
        document.querySelector(".openCookies").onclick = openCookies;
        document.querySelector(".policyContent").style.overflowY = "auto";
        document.querySelector(".policyContent").style.height = "calc(100% - 6rem)";
    }, [])

    const handleClose = () => {
        setTermsPopup("");
    };

    const openPrivacy = () => {
        setTermsPopup("privacy");
    }

    const openCookies = () => {
        setTermsPopup("cookies");
    }

    return (
        <BlurredBackgroundPopup action={handleClose}
                                className={`${styles.policyOuterContainer} ${!isMobile ? styles.desktop : ""}`}>
            <div ref={ref} className={styles.policyInnerContainer}/>
        </BlurredBackgroundPopup>
    )
})

export default TermsOfConditions;