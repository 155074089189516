import React from "react";
import gif from "../../assets/panda icons/spinner.gif";
import style from "./customButton.module.css";

const CustomButton = ({action = () => {}, variant, disabled  = false, isLoading = false, className='', isValid = true, children}) => {

    return (
        <button
            onClick={action}
            disabled={disabled}
            className={`${variant === "white" ? style.white_btn : variant === "gray" ? style.gray_btn : `${style.signin_btn} ${className}`} ${className}`}
            >
            {isValid ? isLoading ? <img src={gif} alt="" className={style.gif} /> : children : <div className={style.custom_loader}/>}
        </button>
    )
}

export default CustomButton;