import React from 'react'
import {observer} from 'mobx-react-lite'
import {isMobile} from 'react-device-detect'

import {tr} from '../../helpers/translations/tr'
import {useNavigateTo} from "../../helpers/navigateTo";

import {authStore} from "../../store/authStore";

import {cacheService} from "../../services/cacheService";

import SigninLayout from '../SignIn/SigninLayout'
import CustomButton from '../CustomButton'

import notFoundSvg from "../assets/404.svg"

import style from '../SignIn/signin.module.css'

const NotFoundPage = observer(() => {
    const {navigateTo} = useNavigateTo();
    const {} = authStore;

    const handleReload = async () => {
        if(cacheService.getAuthToken()) {
            navigateTo("/dashboard", {replace: true})
        } else {
            navigateTo("/", {replace: true})
        }
    };

    return (
        <SigninLayout>
            <div className={style.expired_component}>
                <img alt={"not found"} src={notFoundSvg} className={style.expired_image}/>
                <h1 className={style.auth_title}>{tr("page_not_found")}</h1>
                <p className={style.auth_desc}>{tr("never_exist")}</p>
                <CustomButton
                    action={handleReload}
                    className={`${style.full_screen} ${isMobile ? style.btn_bottom : ""}`}
                >
                    {tr("home")}
                </CustomButton>
            </div>
        </SigninLayout>
    )
})

export default NotFoundPage;