import style from './svgJs.module.scss'

const NextBtnSvg = ({
                        action = () => {
                        }, onlyWhite, className = ""
                    }) => {
    return (
        <svg onClick={action} className={`${style.navBtn} ${className}`} width="3.6rem" height="3.6rem"
             viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.46291 -6.7189e-07C1.67907 -6.52993e-07 1.87121 0.0720512 2.01531 0.216155L5.85807 4.05892C6.1703 4.34713 6.1703 4.85149 5.85807 5.13969L2.01531 8.98246C1.7271 9.29468 1.22274 9.29468 0.934532 8.98246C0.622308 8.69425 0.622308 8.18989 0.934532 7.90168L4.2249 4.61131L0.934533 1.29693C0.622309 1.00872 0.622309 0.504362 0.934533 0.216155C1.07864 0.0720511 1.27077 -6.88687e-07 1.46291 -6.7189e-07Z"
                className={onlyWhite ? style.onlyWhiteFill : style.fill}/>
        </svg>
    )
}

export default NextBtnSvg