import {useEffect, useState, useMemo, useCallback} from 'react'
import Web3 from 'web3'
import {useAccount, useBalance, useSendTransaction, useWaitForTransactionReceipt} from 'wagmi'
import {parseEther} from "viem";
import ERC721 from '../data/metamask/ERC721.json'
import TokenSale from '../data/metamask/TokenSale.json'
import {nftStore} from '../store/nftStore'
import {keyboardLockStore} from '../store/keyboardLockStore'
import {authStore} from '../store/authStore'
import {dashboardStore} from '../store/dashboardStore'
import {cacheService} from '../services/cacheService'
import apiService from '../services/apiServices'
import {BAHANUT_RPC_URL, NFT_CEREBRUM_ADDRESS, NFT_SALE_ADDRESS} from '../constants/nft'
import {convertIpfsUriToUrl, getNftDataInfo} from '../helpers/nft'
import {ipfsUriToUrl} from '../utils'

const web3 = new Web3(BAHANUT_RPC_URL)
const nftContract = new web3.eth.Contract(ERC721.abi, NFT_CEREBRUM_ADDRESS)
const saleContract = new web3.eth.Contract(TokenSale.abi, NFT_SALE_ADDRESS)
// const nftContract = new web3.eth.Contract(ERC721.abi, "0x58795f11b56f9079fb170f1a06e04873f130e66f")

export const useNftData = () => {
    const {selectedCerebrumNft, setContractAddress} = nftStore
    const {setKeyboardLocked} = keyboardLockStore
    const [uri, setUri] = useState('')
    const [owner, setOwner] = useState('')
    const [price, setPrice] = useState('')
    const [salePrice, setSalePrice] = useState('')
    const [name, setName] = useState('')
    const [status, setStatus] = useState('')
    const [value, setValue] = useState('')
    const [saleValue, setSaleValue] = useState('')
    const [image, setImage] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false);


    useEffect(() => {
        if (!uri) return
        (async () => {
            const fetchedData = await getNftDataInfo(uri)
            if (fetchedData) {
                setName(fetchedData.name)
                setDescription(fetchedData.description)
                setImage(ipfsUriToUrl(fetchedData.image))
            }
        })()
    }, [uri]);


    useEffect(() => {
        setKeyboardLocked(false)
        const getTokenData = async () => {
            setLoading(true)
            try {
                const modifiedURI = await convertIpfsUriToUrl(selectedCerebrumNft, nftContract)
                setUri(modifiedURI)

                const contractOwner = await nftContract.methods.ownerOf(selectedCerebrumNft).call()

                setOwner(contractOwner.toLowerCase())
                setContractAddress(contractOwner.toLowerCase())
                const data = await saleContract.methods.tokenIdToSaleInfo(selectedCerebrumNft).call()
                const saleData = await saleContract.methods.saleInfo(selectedCerebrumNft).call()
                const {price, status, name} = data
                const {price: salePrice} = saleData;
                const ethBalance = web3.utils.fromWei(price, 'ether')
                const ethSaleBalance = web3.utils.fromWei(salePrice, 'ether')
                setValue(price?.toString())
                setSaleValue(salePrice?.toString())
                setPrice(ethBalance?.toString())
                setSalePrice(ethSaleBalance?.toString())
                setStatus(status?.toString())
                !!name && setName(name?.toString())
            } catch (error) {
                console.error('ERROR:', error)
            } finally {
                setLoading(false)
            }
        }

        if (selectedCerebrumNft) getTokenData()

        return () => {
            setKeyboardLocked(true)
        }
    }, [selectedCerebrumNft, setContractAddress, setKeyboardLocked])

    return {
        uri,
        image,
        description,
        owner,
        price,
        name,
        setName,
        status,
        value,
        loading,
        imageLoaded,
        setImageLoaded,
        salePrice,
        saleValue
    }
}

export const useAllNfts = (address) => {
    const [allOnSaleNftsInfo, setAllOnSaleNftsInfo] = useState([])
    const [allOnSaleNfts, setAllOnSaleNfts] = useState([])
    const [limit, setLimit] = useState(0)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [doesntLoad, setDoesntLoad] = useState(false)

    useEffect(() => {
        const getNfts = async () => {
            setLoading(true)
            try {
                const allNfts = await saleContract.methods.listedTokens().call();
                setLimit(allNfts.length)
                setAllOnSaleNfts(allNfts)
            } catch (error) {
                console.error('ERROR:', error)
            } finally {
                setLoading(false)
            }
        }
        getNfts()
    }, [])

    useEffect(() => {
        getAdditionalInfo()
    }, [allOnSaleNfts, limit, count]);

    const getAdditionalInfo = async () => {
        if (count < limit) {
            setLoading(true)
            // setTimeout(async () => {
                try {
                    const data = await saleContract.methods.tokenIdToSaleInfo(parseInt(allOnSaleNfts[count].tokenId)).call()
                    if ((data?.status).toString() === '1') {
                        const owner = await nftContract.methods.ownerOf(parseInt(allOnSaleNfts[count].tokenId)).call()
                        if (address !== `${owner}`.toLowerCase()) {
                            try {
                                const tokenUri = await convertIpfsUriToUrl(parseInt(allOnSaleNfts[count].tokenId), nftContract)
                                const myData = await getNftDataInfo(tokenUri)
                                const image = await ipfsUriToUrl(myData.image)
                                const price = web3.utils.fromWei(allOnSaleNfts[count].price, 'ether')
                                setAllOnSaleNftsInfo(prev => ([...prev, {
                                    ...allOnSaleNfts[count],
                                    ...myData,
                                    image,
                                    owner,
                                    price,
                                    tokenUri,
                                }]))
                            } catch (e) {
                                setDoesntLoad(true)
                                console.warn('ERROR: ', e)
                            }
                        }
                    }
                    setCount(prev => prev + 1)
                } catch (error) {
                    console.error('ERROR:', error)
                }
            // }, 500)
        } else {
            setLoading(false)
        }
    }

    return {
        allOnSaleNfts: allOnSaleNftsInfo,
        loading,
        doesntLoad
    }
}


export const useNftTransaction = () => {    
    const {selectedCerebrumNft, metamaskAddress} = nftStore;
    const {setShowWalletPopup} = dashboardStore;
    const {setInviteMessage} = authStore;
    const [account, setAccount] = useState("");
    const [pending, setPending] = useState(false);
    const {address, isConnected} = useAccount();
    const {saleValue} = useNftData()
    const {data} = useBalance({
        address: address,
    })
    const correctAddress = address || metamaskAddress;


    const {sendTransaction, data: hash, error} = useSendTransaction({
        request: {
            to: NFT_SALE_ADDRESS,
            abi: TokenSale.abi,
            functionName: "buy",
        }
    });
    const {isSuccess: isConfirmed} =
        useWaitForTransactionReceipt({
            hash,
        });

    useMemo(() => {
        if (!!error) {
            setPending(false);
            setInviteMessage("Transaction Execution Error.");
        }

        return error
    }, [error]);

    const handleConfirm = useCallback(async () => {
        if (isConfirmed) {
            setPending(false);
            const data = {
                "nft_id": selectedCerebrumNft,
                "nft_price": saleValue,
                "user_public_id": cacheService.getMyId,
                "user_chain_address": correctAddress,
            };
            try {
                await apiService.successBuyTransaction(data);
                setInviteMessage("Thank You for Your Purchase.");
            } catch (e) {
                console.warn("ERROR: ", e);
            }
        }
    }, [isConfirmed]);

    const buyToken = async (address, value) => {
        try {
            await handleConfirm();
            await sendTransaction({
                abi: TokenSale.abi,
                functionName: "buy",
                to: NFT_SALE_ADDRESS,
                value: parseEther(`${web3.utils.fromWei(value, 'ether')}`)
            });
        } catch (error) {
            setInviteMessage("Transaction Execution Error.");
            console.error("Transaction Failed:", error);
        }
    };

    const walletConnectBuy = async ()=> {
        if(web3.utils.fromWei(saleValue, 'ether') > data.formatted){
            return setInviteMessage("Insufficient funds");
        }
        try {
            sendTransaction({
                abi: TokenSale.abi,
                functionName: "buy",
                to: NFT_SALE_ADDRESS,
                value: saleValue,
            }, {
                onSuccess: handleConfirm,
            });
        } catch (error) {
            setInviteMessage("Transaction Execution Error.");
            console.error("Transaction Failed:", error);
        }

    }
    
    const buyTransaction = async () => {
        try {
            if (!window.ethereum && !window.ethereum?.isConnected() && !isConnected) {
                setInviteMessage("Please Add Wallet");
                setShowWalletPopup(true);
                console.error("MetaMask or another Ethereum provider is not installed");
            } else {
                if ((window.ethereum?.isConnected() || isConnected) && !!correctAddress) {
                    setPending(true);
                    if (window.ethereum?.isConnected()) {
                        try {
                            const web3 = new Web3(window.ethereum);

                            const saleContract = new web3.eth.Contract(TokenSale.abi, NFT_SALE_ADDRESS);
                            const transaction = await saleContract.methods.buy(selectedCerebrumNft).send({
                                from: correctAddress,
                                value: saleValue,
                                to: NFT_SALE_ADDRESS
                            });

                            transaction.on("transactionHash", function (hash) {
                                console.log("Transaction Hash:", hash);
                            });

                            transaction.on("receipt", async (receipt) => {
                                const data = {
                                    "nft_id": selectedCerebrumNft,
                                    "nft_price": saleValue,
                                    "user_public_id": cacheService.getMyId,
                                    "user_chain_address": correctAddress,
                                };

                                setInviteMessage("Thank You for Your Purchase.");

                                try {
                                    await apiService.successBuyTransaction(data);
                                } catch (e) {
                                    console.warn("ERROR: ", e);
                                }
                                console.log("Transaction Receipt:", receipt);
                            });

                            transaction.on("error", function (error) {
                                setPending(false);
                                setInviteMessage("Transaction Execution Error.");
                                console.error("Transaction Error:", error);
                            });
                        } catch (e) {
                            setInviteMessage("Transaction Execution Error.");
                            setPending(false);
                            console.warn("Error occurred during transaction : ", e);
                        }
                    } else {
                        try {
                            await buyToken(address, saleValue);
                        } catch (e) {
                            console.warn("Error occurred during transaction : ", e);
                        }
                    }
                } else {
                    setShowWalletPopup(true);
                }
            }
        } catch (e) {
            setPending(false);
            console.warn("ERROR: ", e);
        }
    };


    return {buyTransaction, walletConnectBuy, pending, account}
}