import { useState } from "react";
import { observer } from "mobx-react-lite";
import {ReactComponent as Stroke} from "../../../assets/icons/select_icon.svg";
import SelectList from "./SelectList/SelectList";
import styles from "./styles.module.scss";

 const CustomSelect = observer(({genderHandler, selectedItem, placeholder, selectList=[]})=> {
    const [open, setOpen] = useState(false)

    return (
        <div className={`${styles.department_select} ${open ? styles.open_list : ''}`}>
            <div className={`${styles.option_defoult} ${open ? styles.disabled : ''}`} onClick={()=> setOpen(prev => !prev)}>
                <div className={styles.option_title}>{selectedItem ? selectedItem.title : placeholder}</div>
                <div className={styles.option_arrow}><Stroke/></div>
            </div>
            {
                open && <SelectList open={open} setOpen={setOpen} setGender={genderHandler} selectList={selectList}/>
            }
        </div>
    )
})

export default CustomSelect