import { useEffect } from 'react'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { errorStore } from '../store/errorStore'
import { unityStore } from '../store/unityStore'
import { useNavigateTo } from '../helpers/navigateTo'
import {clearCache} from "../helpers/clearCache";

const useErrorHandler = () => {
    const { errors,
        // setError,
        getErrorOptions } = errorStore
    const { setIsActive } = unityStore
    const { navigateTo } = useNavigateTo()
    const { pathname } = useLocation()
    const condidate = useMemo(() => Object.keys(errors).map(item => errors[item] ? item : false)[0], [errors])
    const options = useMemo(() => {
        if (!condidate) return null
        if (condidate === 'invalid_credentials' || condidate === 'permission_denied' && pathname.includes('/dashboard')) {
            // if (condidate === 'permission_denied' && pathname.includes('/dashboard')) {
            //     setError({ key: 'permission_denied' }, false)
            // }
            return { redirectPath: '/' }
        }
        const errorOptions = getErrorOptions(condidate)
        return errorOptions || { redirectPath: false }
    }, [errors])

    useEffect(() => {
        if (options?.redirectPath) {
            setIsActive(false)
            if (pathname.includes('guest')) {
                navigateTo(pathname)
            } else if (options.redirectPath === '/') {
                clearCache();
                navigateTo(options.redirectPath)
            } else {
                navigateTo(options.redirectPath)
            }
        }
    }, [options])
}

export default useErrorHandler