import { observer } from "mobx-react-lite";
import { useRef } from "react";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import styles from "./styles.module.scss";

 const SelectList = observer(({open, setOpen, setGender, selectList=[]})=> {
    const listRef = useRef(null)
    useOnClickOutside(listRef, () => setOpen(false));

    const selectHandler = async (gen) => {
        setGender(gen)
        setOpen(false)
    }

    return (
        <div className={styles.department_list} ref={listRef}>
            {
                open && (
                    selectList.map(item => {
                        return (
                            <div
                                key={item.public_id}
                                className={styles.department_list_item}
                                onClick={()=> selectHandler(item)}
                            >
                                {item.title}
                            </div>
                        )
                    })
                )
            }
        </div>
    )
})

export default SelectList