import { makeObservable, observable, action } from 'mobx';

class ToolTipStore {
    top = null;
    left = null;
    visible = false;
    tooltipPrefix = '';
    toolTipType = '';

    constructor() {
        makeObservable(this, {
            top: observable,
            left: observable,
            tooltipPrefix: observable,
            visible: observable,
            setToolTipInfo: action.bound,
            hideToolTip: action.bound,
            setToolTipPrefix: action.bound,
        });
    }

  setToolTipInfo(e) {
    const containerRect = e.target.getBoundingClientRect();
    this.top = containerRect.top;
    this.left = containerRect.left + containerRect.width * 0.5;
    this.tooltipPrefix = e.target.getAttribute("content")
    this.visible = true;
    this.toolTipType = e.target.getAttribute("tooltiptype")
  }

  setToolTipPrefix(type, prefix){
    if(this.toolTipType === type){
      this.tooltipPrefix = prefix;
    }
  }

  hideToolTip() {
    this.tooltipPrefix = '';
    this.visible = false;
    this.toolTipType = '';
  }
}

export const toolTipStore = new ToolTipStore();

