import React from "react";
import {observer} from "mobx-react-lite";

import {authStore} from "../../store/authStore";

import FastexVerseLogo from "../Logo";
import BackBtn from "../BackBtn";
import TermsOfConditions from "../Policies/TermsOfConditions";
import PrivacyPolicy from "../Policies/PrivacyPolicy";
import CookiesPolicy from "../Policies/CookiesPolicy";
import IncompleteVerificationPopup from '../Popups/IncompleteVerification';

import style from "./signin.module.css";

const SigninLayout = observer(({children, isActiveBackBtn = false, backBtnHref}) => {
    const {termsPopup, showVerifyPopup} = authStore;

    return (
        <div className={style.background}>
            {termsPopup === "terms" ? <TermsOfConditions/> : <></>}
            {termsPopup === "privacy" ? <PrivacyPolicy/> : <></>}
            {termsPopup === "cookies" ? <CookiesPolicy/> : <></>}
            {showVerifyPopup ? <IncompleteVerificationPopup /> : <></>}
            <div className={style.container}>
                {isActiveBackBtn && <BackBtn backTo={backBtnHref}/>}
                <div className={style.logo}><FastexVerseLogo/></div>
                <div className={style.auth_content}>
                    {children}
                </div>
            </div>
        </div>
    )
});

export default SigninLayout;