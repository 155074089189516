import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { errorStore } from "../../store/errorStore";
import {translationStore} from "../../store/translationStore";

export const Error = observer(({error = "", children, className})=> {
    const {errors} = errorStore;
    const {translationData} = translationStore;
    const errorKeys = useMemo(()=> {
        if(!error.includes(",")) {
            if(errors[error]){
                return [error]
            }
        } else {
            return error.split(",").map(item => {
                if(errors[item.trim()]){
                    return item.trim()
                }
            })
        }
    },[errors])

    const errorMap = {
        'user_not_found': translationData?.["user_not_found"] || "Incorrect password, please try again",
        'email_not_confirm': translationData?.["email_not_confirm"] || "Please confirm your email address",
        'password_is_not_equal': translationData?.["passwords_match"] || "Make sure both passwords are identical.",
        'incorrect_email': translationData?.["incorrect_email"] || "Incorrect email, please try again",
        'incorrect_code': translationData?.["incorrect_code"] || "Incorrect code. Please try again.",
        'incorrect_password': translationData?.["password_validation"] || "Be sure to use at least one uppercase, lowercase letters, a number and a symbol.",
        "upload_image": translationData?.["upload_image"] || "Please upload the image",
        "empty_name": translationData?.["empty_name"] || "Please fill in the field name",
        "empty_email": translationData?.["empty_email"] || "Make sure the email is valid",
        "empty_create_pass": translationData?.["empty_pass"] || "Make sure to create a strong password.",
        "empty_confirm_pass": translationData?.["empty_pass"] || "Make sure to create a strong password.",
        "max_charger50": translationData?.["max_charger50"] || "Should contain up to 50 characters",
        "max_charger250": translationData?.["max_charger250"] || "Should contain up to 250 characters",
        "empty_description": translationData?.["empty_description"] || "Please fill in the field description",
        "empty_price": translationData?.["empty_price"] || "Please fill in the field price",
        "empty_value": translationData?.["empty_value"] || "Please fill in the field",
        "link_format": translationData?.["link_format"] || "Incorrect link",
        "template_name_error": translationData?.["template_name_error"] || "Please fill in the field",
        "email_already_registered": translationData?.["email_already_registered"] || "This email is already registered.",
        "user_does_not_exist_error": translationData?.["user_does_not_exist"] || "Provided email is not registered yet.",
    }

    return (
        <div className="error_blok">
            {children}
            {
                errorKeys && (
                    <>
                        {
                            errorKeys.map((item, index) => {
                                if(!item) return null 
                                return (
                                    <div className={["root-page-error-message", className].join(" ")} key={index}>{errorMap[item]}</div>
                                )
                            })
                        }
                    </>
                )
            }
        </div>
    )
})