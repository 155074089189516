import React from 'react'
import { Route, Routes } from 'react-router-dom'

import TransitionRouter from '../../components/TransitionRouter'
import CustomSwitch from '../../ui/CustomSwitch'
import LanguageWrapper from '../../ui/LanguageWrapper'
import NotFoundPage from '../../ui/NotFoundPage'

import routes from '../../routes'
import useErrorHandler from '../../hooks/useErrorHandler'

const RoutesList = () => {
    useErrorHandler();

    return (
        <TransitionRouter>
            <CustomSwitch>
                <Routes>
                    <Route path="/:lang?" element={<LanguageWrapper />}>
                        {routes.map((item, index) => (
                            <Route
                                key={index}
                                path={item.path}
                                element={<item.component />}
                                title={item.title}
                                exact
                            />
                        ))}
                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                </Routes>
            </CustomSwitch>
        </TransitionRouter>
    )
}

export default RoutesList