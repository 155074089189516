import {useNavigate, useParams} from "react-router-dom";
import {errorStore} from "../store/errorStore";

export const useNavigateTo = () => {
    const navigate = useNavigate();
    const {lang} = useParams();
    const {clearErrors} = errorStore;

    const navigateTo = (to, state = {}, hardReset  = false) => {
        if (typeof to === "object") {
            const redirectTo = {
                ...to,
                pathname: lang ? `/${lang}${to.pathname}` : to.pathname
            };
            navigate(
                redirectTo,
                state
            );
        } else {
            if(to) {
                if (lang) {
                    if(hardReset) {
                        window.location.assign(`/${lang}${to}`);
                    } else {
                        navigate(`/${lang}${to}`, state)
                    }
                } else {
                    if(hardReset) {
                        window.location.assign(to);
                    } else {
                        navigate(to, state)
                    }
                }
            } else {
                navigate(to)
            }
        }

        if(to === "/") {
            clearErrors()
        }
    }

    return {
        navigateTo
    }
}