import { observer } from "mobx-react-lite";
import style from './CreatePasswordPopup.module.scss'
import { unityLayoutStore } from "../../../store/unityLayoutStore";
import { cacheService } from "../../../services/cacheService";
import eyeOpen from "../../assets/icons/eye_open.svg";
import eyeClosed from "../../assets/icons/eye_closed.svg";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import apiService from "../../../services/apiServices";
import { tr } from "../../../helpers/translations/tr";
import {DecoratorForRoutionAuthContext} from "../../../Providers/DecoratorForRoutionAuth";

export const CreatePasswordPopup = observer(() => {
    const {authorization} = useContext(DecoratorForRoutionAuthContext);
    const { userData, setShowPassPopup } = unityLayoutStore
    const [inputType, setInputType] = useState('password');
    const [confirmInputType, setConfirmInputType] = useState('password');
    const RegEx = [/(?=.*[a-z])/, /(?=.*[A-Z])/, /(?=.*[0-9])/, /(?=.*[\W])/]

    const [password, setPassword] = useState("");
    const [passValid, setPassValid] = useState(null);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPassValid, setConfirmPassValid] = useState(null);
    const confirmPassRef = useRef();

    const [buttonStatus, setButtonStatus] = useState(false);

    useEffect(() => {
        cacheService.set("Authorization", `Token ${userData.token}`);
    }, [])

    useEffect(() => {
        if (passValid && confirmPassValid) {
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
        }
    }, [passValid, confirmPassValid])


    const confirmPass = async () => {
        if (!buttonStatus) return;
        try {
            await apiService.setUserCreatePassword({
                email: userData.email,
                new_password1: password,
                new_password2: confirmPassword
            })
            setShowPassPopup(false)
            authorization(userData);
        } catch (e) {
            console.log("ERROR: ", e)
        }
    }

    const checkPass = (e) => {
        setPassword(e.target.value)
        if (RegEx.every((regex) => regex.test(e.target.value))) {
            setPassValid(true)
        } else {
            setPassValid(false)
        }
    }

    const checkConfirmPass = (e) => {
        setConfirmPassword(e.target.value)
        if (password === e.target.value) {
            setConfirmPassValid(true)
        } else {
            setConfirmPassValid(false)
        }
    }

    const uppercaseValid = useMemo(() => {
        return /(?=.*[A-Z])/.test(password);
    }, [password])

    const lowercaseValid = useMemo(() => {
        return /(?=.*[a-z])/.test(password);
    }, [password])

    const numberValid = useMemo(() => {
        return /(?=.*[0-9])/.test(password);
    }, [password])

    const symbolValid = useMemo(() => {
        return /(?=.*[\W])/.test(password);
    }, [password])

    return (
        <>
            <div className={style.blurredBackgroundPopup}></div>
            <div className={style.popup_container}>
                <div className={style.popup_header}>
                    <div className={style.popup_title}>
                        {tr("create_new_password")}
                    </div>
                    {/* <div className={style.popup_subtitle}>
                        (min 8 caracter)
                        Be sure to use at least one uppercase, lowercase letters, a number and a symbol.
                    </div> */}
                </div>
                <div className={style.popup_form}>
                    <div className={style.password_input_block}>
                        <label htmlFor="pass">{tr("new_password")}</label>
                        <div className={style.input}>
                            <input className={`${passValid === false ? style.error : ''}`} value={password} onChange={e => checkPass(e)} id="pass" type={inputType} onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    confirmPassRef.current.focus()
                                }
                            }} />
                            <div className={`${style.pass_eye_container}`} onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}>
                                <img className={style.pass_eye} alt="pass_visibility" src={inputType === 'password' ? eyeClosed : eyeOpen} />
                            </div>
                        </div>
                        <div className={style.input_additional_info}>
                            {tr("password_should_contain")} <span
                                className={`${uppercaseValid ? style.active : style.inactive}`}>{tr("uppercase")}</span>, <span
                                    className={`${lowercaseValid ? style.active : style.inactive}`}>{tr("lowercase")}</span>, <span
                                        className={`${symbolValid ? style.active : style.inactive}`}>{tr("special_symbol")}</span>, <span
                                            className={`${numberValid ? style.active : style.inactive}`}>{tr("number")}</span> {tr("and")} <span
                                                className={`${password.length >= 8 ? style.active : style.inactive}`}>{tr("min_8_char")}</span>.
                        </div>
                        {/* {passValid === false && <div className={style.error_text}>Incorrect password, please try again</div>} */}
                    </div>
                    <div className={style.password_input_block}>
                        <label htmlFor="confirm-pass">{tr("confirm_password")}</label>
                        <div className={style.input}>
                            <input ref={confirmPassRef} className={`${confirmPassValid === false ? style.error : ''}`} value={confirmPassword} onChange={e => checkConfirmPass(e)} id="confirm-pass" type={confirmInputType} onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    confirmPass()
                                }
                            }} />
                            <div className={style.pass_eye_container} onClick={() => setConfirmInputType(confirmInputType === 'password' ? 'text' : 'password')}>
                                <img className={style.pass_eye} alt="pass_visibility" src={confirmInputType === 'password' ? eyeClosed : eyeOpen} />
                            </div>
                        </div>
                        {confirmPassValid === false && <div className={style.error_text}>Make sure both passwords are identical.</div>}
                    </div>
                    <button disabled={!buttonStatus && !(passValid || confirmPassValid)} className={`${style.create_button} ${!buttonStatus && style.disabled}`} onClick={confirmPass}>
                        {tr("create")}
                    </button>
                </div>
            </div>
        </>
    )
})