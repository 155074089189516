import React from "react";
import {observer} from "mobx-react-lite";

import {tr} from "../../helpers/translations/tr";
import {useNavigateTo} from "../../helpers/navigateTo";

import style from "./backBtn.module.css";
import {errorStore} from "../../store/errorStore";

const BackBtn = observer(({backTo}) => {
    const {navigateTo} = useNavigateTo();
    return (
        <button onClick={() => {
            errorStore.clearErrors()
            navigateTo(backTo)
        }} className={style.back_btn_container}>
            <span className={style.back_btn_icon}/>
            <span className={style.back_btn_title}>{tr("back")}</span>
        </button>
    )
})

export default BackBtn;