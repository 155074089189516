import React, { lazy, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { unityStore } from './store/unityStore'
import { authStore } from './store/authStore'
import { dashboardStore } from './store/dashboardStore'
import DoubleLogin from './components/Popups/DoubleLogin'
import InviteShowPopup from './components/Popups/InviteShowPopup'
import ErrorModal from './components/ModalError'
import Mobile from './containers/mobile'
import ParticipantsPopup from './components/Popups/ParticipantsPopup'
import InviteToHomeSpace from './components/Popups/InviteToHomeSpace'
import useRotate from './hooks/useRotate'
import { roomStore } from './store/roomStore'
import InvalidTicketPopup from './components/Popups/InvalidTicketPopup'
import MetaMaskPopup from './components/Metamask/MetaMaskPopup'
import { web3Store } from './store/web3Store'
import TanstackProvider from './components/Metamask/TanstackProvider'
import WalletPopup from './components/Popups/WalletPopup'
import RotateDevice from './components/RotateDevice'
import { notificationStore } from './store/notificationStore'
import Notification from './ui/Notification'
import { nftStore } from './store/nftStore'
import CerebrumNftNew from './components/Metamask/CerebrumNft/CerebrumNftNew'
import TicketUploadGuest from './ui/Popups/TicketUploadGuest'
import { buyCryptoStore } from './store/buyCryptoStore'
import { BuyCrypto } from './components/Dashboard/Events/EventsHeader/UserProfile/BuyCrypto'
import DecoratorForRoutionAuth from './Providers/DecoratorForRoutionAuth'
import GuestPopup from './ui/Popups/GuestPopup'
import RoutesList from './routes/RoutesList'
import { CreatePasswordPopup } from './ui/Popups/CreatePasswordPopup'
import { unityLayoutStore } from './store/unityLayoutStore'
import {UserInfoPopup} from "./ui/UserInfoPopup/UserInfoPopup";

const TicketPopup = lazy(() => import('./components/Popups/TicketPopup'))
const UnityLayout = lazy(() => import('./containers/unityLayout'))

const App = observer(() => {
    const {loadUnity, isActiveMobile} = unityStore;
    const {doubleLogin, inviteMessage, showUserDataPopup, setShowUserDataPopup} = authStore;
    const {inviteData, clearInviteData, showWalletPopup} = dashboardStore;
    const {notification, clearNotification} = notificationStore;
    const {ticketRoomId, setTicketPopupId, isInvalidTicketPopup, showGuestPopup, showGuestSignInPopup} = roomStore;
    const {popupToggle} = web3Store;
    const {showQrCode} = buyCryptoStore;
    const {showPassPopup} = unityLayoutStore

    const showRotateDevice = useRotate(false)
    const { selectedCerebrumNft } = nftStore

    return (
        <Suspense fallback={null}>
            <DecoratorForRoutionAuth>
                <TanstackProvider>
                    <RoutesList />
                    <Suspense fallback={null}>
                        {loadUnity && !isActiveMobile && <UnityLayout />}
                        {isActiveMobile && <Mobile />}
                    </Suspense>
                    <Suspense fallback={null}>
                        {ticketRoomId ? <TicketPopup setToggle={setTicketPopupId} /> : <></>}
                    </Suspense>
                    {doubleLogin && <DoubleLogin />}
                    {!!inviteMessage && <InviteShowPopup />}
                    {!!showGuestPopup && <TicketUploadGuest />}
                    {!!showGuestSignInPopup && <GuestPopup />}
                    {!!inviteData?.sender_user && (
                        <InviteToHomeSpace
                            user={inviteData?.sender_user}
                            roomLink={inviteData?.link}
                            clearData={clearInviteData}
                        />
                    )}
                    {popupToggle && <MetaMaskPopup />}
                    <ParticipantsPopup />
                    <ErrorModal />
                    {isInvalidTicketPopup && (
                        <InvalidTicketPopup />
                    )}
                    {
                        showUserDataPopup && (
                            <UserInfoPopup setShowUserDataPopup={setShowUserDataPopup}/>
                        )
                    }
                    {showRotateDevice && <RotateDevice />}
                    {showWalletPopup && <WalletPopup />}
                    {showPassPopup && <CreatePasswordPopup />}
                    {!!notification && <Notification type={notification.type} text={notification.text}
                                                     hideNotification={clearNotification} />}
                    {!!selectedCerebrumNft && <CerebrumNftNew />}
                    {
                        showQrCode && <BuyCrypto />
                    }
                </TanstackProvider>
            </DecoratorForRoutionAuth>
        </Suspense>
    )
})

export default App
