import React, {useEffect, useRef} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {observer} from "mobx-react-lite";
import {authStore} from "../../store/authStore";
import {unityStore} from "../../store/unityStore";
import {keyboardLockStore} from "../../store/keyboardLockStore";
import BlurredBackgroundPopup from "../../ui/Popups/PopupsWrappers/BlurredBackgroundPopup";
import styles from "../../ui/Popups/popups.module.css";
import {tr} from "../../helpers/translations/tr";
import {useLocation} from "react-router-dom";
import {clearCache} from "../../helpers/clearCache";

const DoubleLogin = observer(() => {
    const { setDoubleLogin } = authStore;
    const { setLoadUnity } = unityStore;
    const {setKeyboardLocked} = keyboardLockStore;
    const ref = useRef();
    const location = useLocation();

    const onUserNavigateForLogin = () => {
        clearCache()
        setDoubleLogin(false);
        setLoadUnity(false);
        setKeyboardLocked(true);
        window.location.assign(`${location.pathname.split("/dashboard")[0]}/`);
    };

    const handleClose = () => {
        onUserNavigateForLogin();
    }

    useOnClickOutside(ref, () => handleClose(false));

    useEffect(() => {
        setTimeout(handleClose, 5000)
    }, [])

    return (
        <BlurredBackgroundPopup revert={true} action={handleClose}>
            <h2 className={styles.popupTitle}>{tr("double_login")}</h2>
            <p className={styles.popupDescription}>{tr("you_can_use")}</p>
            <div className={styles.popupButtonsContainer}>
                <button onClick={handleClose} className={styles.popupButton}>{tr("ok")}</button>
            </div>
        </BlurredBackgroundPopup>
    );
});

export default DoubleLogin;
