import React, {useRef} from "react";
import {observer} from "mobx-react-lite";
import Web3 from "web3";
import {nftStore} from "../../../store/nftStore";
import {BAHANUT_RPC_URL} from "../../../constants/nft";
import Background from "../../Inventory/Categories/NFT/Background/Background";
import WalletOptions from "./WalletOptions";
import { useAccount  } from 'wagmi'

const WalletPopup = observer(() => {
    const {setMetamaskAddress, setBalance} = nftStore;
    const { address } = useAccount()
    const fetchMetaMaskData = async () => {
        try {
            window.web3 = new Web3(window.ethereum);
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            try {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{chainId: window.web3.utils.toHex(5165)}],
                });
            } catch (switchError) {
                try {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: window.web3.utils.toHex(5165),
                                chainName: "Bahamut",
                                rpcUrls: [BAHANUT_RPC_URL],
                                nativeCurrency: {
                                    name: "Fasttoken",
                                    decimals: 18,
                                    symbol: "FTN"
                                }
                            },
                        ],
                    });
                } catch (e) {
                    console.warn("ERROR: ", e);
                }
            }
            setMetamaskAddress(accounts?.[0])
            let weiBalance = await window.ethereum.request({method: 'eth_getBalance', params: [accounts?.[0], 'latest']});
            // let weiBalance = await window.web3.eth.getBalance(accounts?.[0]);
            let ethBalance = window.web3.utils.fromWei(weiBalance, "ether");
            setBalance(ethBalance);
        } catch (e) {
            console.warn("ERROR: ", e);
        }
    };


    return (
        <Background>
            <div>
                <WalletOptions fetchMetaMaskData={fetchMetaMaskData}/>
                {address}
            </div>
        </Background>
    );
});

export default WalletPopup;