import {useRef} from "react";

import useOnClickOutside from "../../../../hooks/useOnClickOutside";

import styles from "./popupContainer.module.css";

const PopupContainer = ({children, action, className = "", revert = false, closable}) => {
    const ref = useRef();

    const onClose = () => {
        if(closable) {
            setTimeout(() => {
                action(false)
            }, 300)
        }
    }

    useOnClickOutside(ref, onClose);

    return (
        <div ref={ref} className={`${revert ? styles.popupContainerRevert : styles.popupContainer} ${className}`}>
            {closable && <span onClick={onClose} className={styles.closeIcon} />}
            {children}
        </div>
    )
}

export default PopupContainer;