import React, {useState, useEffect} from 'react';

import {tr} from "../../helpers/translations/tr";

import './styles.css';

const Calendar = ({selectedDateHandler}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear() - 4);
  const [selectedMonth, setSelectedMonth] = useState(0);

  useEffect(() => {
    renderCalendar(selectedYear, selectedMonth);
  }, [selectedYear, selectedMonth]);

  const renderCalendar = (year, month) => {
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInPrevMonth = new Date(year, month, 0).getDate();

    const daysArray = [];

    // Days from previous month
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      daysArray.push({
        day: daysInPrevMonth - i,
        currentMonth: false,
      });
    }

    // Days from current month
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push({
        day: i,
        currentMonth: true,
      });
    }

    // Days from next month
    const totalDays = daysArray.length;
    const nextDays = (7 - (totalDays % 7)) % 7;
    for (let i = 1; i <= nextDays; i++) {
      daysArray.push({
        day: i,
        currentMonth: false,
      });
    }

    setDays(daysArray);
  };

  const handleYearChange = (value) => {
    setSelectedYear(parseInt(value));
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(parseInt(value));
  };

  const selectDayHandler = (index, data)=> {
    setSelectedDay(data.day);
    selectedDateHandler({day: data.day, month: selectedMonth, year: selectedYear});
    setDays(prev=> {
      const newState = prev.map((item, i) => {
        if(i === index){
          return {...item, selected: !item.selected}
        }else{
          return {...item, selected: false}
        }
      })
      return newState
    })
  }

  const years = Array.from({ length: 80 }, (_, i) => currentDate.getFullYear() - 83 + i);
  const months = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('en-US', { month: 'short' }));

  return (
    <div id="calendar">
      <div id="calendar-header">
        <select defaultValue={years[years.length-1]} onChange={(e) => handleYearChange(e.target.value)}>
          {years.map((year, i) => (
            <option key={i} value={year}>
              {year}
            </option>
          ))}
        </select>
        <select defaultValue={months[0]} onChange={(e)=> handleMonthChange(e.target.value)}>
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div id="calendar-body">
          <div className="day-names">
            <div>{tr("sun")}</div>
            <div>{tr("mon")}</div>
            <div>{tr("tue")}</div>
            <div>{tr("wed")}</div>
            <div>{tr("thu")}</div>
            <div>{tr("fri")}</div>
            <div>{tr("sat")}</div>
          </div>
          <div id="days">
            {days.map((dayObj, index) => (
              <div key={index} className={dayObj.currentMonth ? 'day' : 'not-current-month'} onClick={()=>selectDayHandler(index, dayObj)}>
                <span className={`${dayObj.selected ? 'selected' : ''}`}>
                  {dayObj.day}
                </span>
              </div>
            ))}
          </div>
        </div>
    </div>
  );
};

export default Calendar;
