import { useConnect, useAccount } from 'wagmi'
import { getConnections } from '@wagmi/core'
import { useEffect, useRef } from 'react';
import {observer} from "mobx-react-lite";
import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import { translationStore } from '../../../../store/translationStore';
import { dashboardStore } from '../../../../store/dashboardStore';
import { bahamut } from 'wagmi/chains'
import { config } from '../../../Metamask/TanstackProvider/config';
import styles from "../walletPopup.module.scss";

const WalletOptions = observer(({fetchMetaMaskData})=> {
  const connections = getConnections(config)
  const {translationData} = translationStore;
  const {setShowWalletPopup} = dashboardStore;
  const {chainId, isConnected} = useAccount()

  const { connectors, connect } = useConnect();
  const walletRef = useRef();
  const closePopup = () => {
    setShowWalletPopup(false);
  };

  useEffect(()=> {
    try {
      const init = async ()=> {
        if(chainId !== 5165 && isConnected){
          await connections[0]?.connector.switchChain({ chainId: bahamut.id })
        }
      }
      init()
    } catch (error) {
      console.log(error);
    }
    
  }, [isConnected])

  
  const connectHandler = async (connector)=> {
    try {
      if(connector.name === "MetaMask"){
        await connect({ connector })
        fetchMetaMaskData()
      }else{
        await connect({ connector })
      }
    } catch (error) {
      console.log("error:", error)
    }
  }

    useEffect(() => {
        if(chainId === 5165) closePopup()
    }, [chainId])

  useOnClickOutside(walletRef, closePopup);
  return (
    <div ref={walletRef} className={styles.walletContainer}>
      <div className={styles.walletHeader}>
          <h1 className={styles.walletTitle}>{translationData?.["connect_wallet"] || "Connect wallet"}</h1>
          <i className={styles.iconClose} onClick={closePopup}></i>
      </div>
      <div className={styles.walletsTypes}>
        {
          connectors.map((connector) => (
            <div key={connector.id} className={`${styles.walletCard} ${styles[connector.name]}`} onClick={() => connectHandler(connector)}>
              <button key={connector.uid} className={`${styles.wallet}`}>
              </button>
              <span className={styles.walletName}>{connector.name}</span>
            </div>
          ))
        }
        
      </div>
    </div>
  )
})
  export default WalletOptions
