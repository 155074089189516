import { useEffect, useMemo, useRef } from 'react'
import gsap from 'gsap'
import { observer } from 'mobx-react-lite'

import { tr } from '../../helpers/translations/tr'

import { SuccessSvg, WarningSvg, RejectSvg } from '../assets/svgJs/notificationIcons'

import styles from './notification.module.css'

const Notification = observer(({ type = 'success', text = '', hideNotification }) => {
    const ref = useRef()

    const notificationData = useMemo(() => {
        return (
            {
                'success': {
                    Icon: SuccessSvg,
                    className: styles.success,
                    notificationText: tr('success_msg'),
                },
                'warning': {
                    Icon: WarningSvg,
                    className: styles.warning,
                    notificationText: tr('error_msg'),
                },
                'error': {
                    Icon: RejectSvg,
                    className: styles.reject,
                    notificationText: tr('error_msg'),
                },
            }
        )
    },[tr])

    useEffect(() => {
        if (!ref.current) return
        const tl = gsap.fromTo(ref.current,
            {
                y: '12rem',
                opacity: 0,
            }, {
                y: '-12rem',
                opacity: 1,
                duration: 1.5,
                ease: 'power3',
                onComplete: () => {
                    setTimeout(() => {
                        tl.reverse()
                    }, 2000)
                    setTimeout(() => {
                        hideNotification()
                    }, 3500)
                },
            })
    }, [hideNotification])

    const { Icon, className, notificationText } = notificationData[type];

    return (
        <div ref={ref} className={`${styles.notificationContainer} ${className}`}>
            <Icon />
            <p className={`${styles.notificationText} ${className}`}>{text || notificationText}</p>
            {/* <span className={`${styles.closeIcon} ${className}`}/> */}
        </div>
    )
})

export default Notification