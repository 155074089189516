import { useMemo } from "react"
import { useLocation } from "react-router-dom"

const useQuery = () =>{
    const {search} = useLocation()
    const queryParams = useMemo(() => new URLSearchParams(search),[search])
    const params = {
    }
    for(let [key,value] of queryParams.entries()){
        params[key] = value
    }
    return params
}

export default useQuery