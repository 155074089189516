import { observer } from "mobx-react-lite";
import { toolTipStore } from "../../store/toolTipStore";
import { useMemo, useRef } from "react";
import { translationStore } from "../../store/translationStore";
import styles from "./styles.module.scss";

const ToolTip = observer(()=> {
    const {top, left, tooltipPrefix, visible, toolTipType} = toolTipStore;
    const {translationData} = translationStore;
    const tooltipRef = useRef(null);

    const tooltipWidth = useMemo(()=> {
        if(!tooltipRef.current) {
            return {
                top: 0,
                left: 0,
            }
        }
        return {
            top: top,
            left: left,
        }
    },[tooltipRef.current, top, left])

    const tooltipContent = useMemo(()=> {
        return translationData?.[tooltipPrefix] || tooltipPrefix
    }, [tooltipPrefix, toolTipType])

    return (
        <div ref={tooltipRef} className={`${styles.absolute} ${visible && !!tooltipContent && styles.show}`} style={tooltipWidth}>
            {tooltipContent}
        </div>
    )
})

export default ToolTip