import style from './svgJs.module.scss'

const CalendariumSvg = ({ active, onlyWhite = false }) => {
    return (
        <svg width="2.4rem" height="2.4rem" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8099_64871)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M12.5392 20C12.1389 20 11.8114 19.6786 11.8114 19.2857C11.8114 18.8929 12.1389 18.5714 12.5392 18.5714H17.6335C19.2418 18.5714 20.5445 17.2929 20.5445 15.7143V5.71429C20.5445 4.13571 19.2418 2.85714 17.6335 2.85714H16.178V3.57143C16.178 3.96429 15.8505 4.28571 15.4502 4.28571C15.0499 4.28571 14.7225 3.96429 14.7225 3.57143V2.85714H7.27754V3.57143C7.27754 3.96429 6.95005 4.28571 6.54978 4.28571C6.14952 4.28571 5.82203 3.96429 5.82203 3.57143V2.85714H4.36652C2.75819 2.85714 1.45551 4.13571 1.45551 5.71429V15.7143C1.45551 17.2929 2.75819 18.5714 4.36652 18.5714H9.4608C9.86106 18.5714 10.1886 18.8929 10.1886 19.2857C10.1886 19.6786 9.86106 20 9.4608 20H4.36652C1.95766 20 0 18.0786 0 15.7143V5.71429C0 3.35 1.95766 1.42857 4.36652 1.42857H5.82203V0.714286C5.82203 0.321429 6.14952 0 6.54978 0C6.95005 0 7.27754 0.321429 7.27754 0.714286V1.42857H14.7225V0.714286C14.7225 0.321429 15.0499 0 15.4502 0C15.8505 0 16.178 0.321429 16.178 0.714286V1.42857H17.6335C20.0423 1.42857 22 3.35 22 5.71429V15.7143C22 18.0786 20.0423 20 17.6335 20H12.5392Z"
                      className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill}/>
                <path
                    d="M9.27129 16.3287C9.14029 16.3287 9.01658 16.2787 8.91469 16.1787C8.74731 16.0144 8.72548 15.7359 8.86375 15.543L11.3891 12.093L7.18264 11.693C6.9352 11.6716 6.73871 11.5144 6.65865 11.2859C6.5786 11.0502 6.6441 10.8073 6.82604 10.643L12.3861 5.75015C12.4807 5.66444 12.6044 5.62158 12.7281 5.62158C12.8591 5.62158 12.9901 5.67158 13.0847 5.77158C13.2521 5.93587 13.2739 6.21444 13.1357 6.4073L10.6104 9.8573L14.8168 10.2573C15.0642 10.2787 15.268 10.4359 15.348 10.6716C15.4281 10.9002 15.3626 11.1502 15.1807 11.3073L9.62061 16.2002C9.526 16.2859 9.40956 16.3287 9.27857 16.3287H9.27129Z"
                    className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill}/>
            </g>
            <defs>
                <clipPath id="clip0_8099_64871">
                    <rect width="22" height="20" className={active ? style.fillActive : onlyWhite ? style.onlyWhiteFill : style.fill}/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default CalendariumSvg;