import { http, createConfig } from 'wagmi'
import { bahamut } from 'wagmi/chains'
import { walletConnect } from 'wagmi/connectors'

const projectId = "978ab44dd0914566c8a6414fa95c5bb1";
export const config = createConfig({
  chains: [bahamut],
  connectors: [
    walletConnect({
      disableProviderPing: false,
      projectId,
      metadata: {
        name: "Fastexverse",
        description: "Fastexverse | Meta verse",
        url: window.location.origin,
        icons: ["https://space.fastexverse.com/static/media/fastex-verse1.595e9017b5215b1d46562b8684843f6d.svg"]
      }, 
    }),
  ],
  transports: {
    [bahamut.id]: http(),
  },
})