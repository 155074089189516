import style from "./svgJs.module.scss";

const CopiaSpace = ({active, onlyWhite}) => {
    return (
        <svg width="2.4rem" height="2.4rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#E8007F"  className={active ? style.strokeActive : onlyWhite ? style.onlyWhiteStroke : style.stroke} strokeWidth="1.5"  style={{transition: "all ease 0.3s"}} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 16V3M12 3L16 7.375M12 3L8 7.375" stroke="#E8007F"  className={active ? style.strokeActive : onlyWhite ? style.onlyWhiteStroke : style.stroke} strokeWidth="1.5"  style={{transition: "all ease 0.3s"}} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    )
}

export default CopiaSpace;