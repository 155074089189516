import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";
import {isMobile} from "react-device-detect";

import {authStore} from "../../../store/authStore";

import {tr} from '../../../helpers/translations/tr';

import BlurredBackgroundPopup from '../../Popups/PopupsWrappers/BlurredBackgroundPopup';

import styles from '../policies.module.scss';

const PrivacyPolicy = observer(() => {
    const ref = useRef();
    const {setTermsPopup} = authStore;

    useEffect(() => {
        ref.current.innerHTML = tr("privacy_policy_full");
        document.querySelector(".openTerms").onclick = openTerms;
        document.querySelector(".openCookies").onclick = openCookies;
        document.querySelector(".policyContent").style.overflowY = "auto";
        document.querySelector(".policyContent").style.height = "calc(100% - 6rem)";
    }, [])

    const handleClose = () => {
        setTermsPopup("");
    };

    const openTerms = () => {
        setTermsPopup("terms");
    };

    const openCookies = () => {
        setTermsPopup("cookies");
    }

    return (
        <BlurredBackgroundPopup action={handleClose}
                                className={`${styles.policyOuterContainer} ${!isMobile ? styles.desktop : ""}`}>
            <div ref={ref} className={styles.policyInnerContainer}/>
        </BlurredBackgroundPopup>
    )
})

export default PrivacyPolicy;