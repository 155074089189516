import {makeObservable, observable, action, computed} from "mobx";
import {unityStore} from "./unityStore";
import { cacheService } from "../services/cacheService";

class DashboardStore {
    rooms = [];
    filteredRooms = null;
    spaces = [];
    myEventsActive = "";
    mySpacesActive = false;
    sortType = "";
    searchValue = "";
    countdown = {};
    gifts = [];
    roomsLimit = 0;
    countLimit = 12;
    limit = 12;
    inviteData = {};
    liveEvent = {};
    showLoading = false;
    renamedRoom = {};
    changedRoomStatusName = "";
    changedStatusRoomData = {};
    showWalletPopup = false;
    openProfile = false;
    showSidebar = true;
    showLanguagePage = false;
    showPrivacyPolicy = false;
    showProfileInfo = false;
    showSocials = false;
    openChooseAvatarType = false;
    avatarCreatorPopup = "";
    showMyAssets = false;
    returnAsset = {};
    dashboardBanners = [];
    activeMenu='';
    showCalendar = true;
    eventRooms = [];
    qualityPopup = false;
    quality = !!cacheService.get("quality") ? +cacheService.get("quality") : 1;
    copiaTab = "welcome";
    aiChatBotModal = false;
    garage = [];
    shops = [];
    games = [];

    constructor() {
        makeObservable(this, {
            rooms: observable,
            filteredRooms: observable,
            spaces: observable,
            myEventsActive: observable,
            mySpacesActive: observable,
            sortType: observable,
            searchValue: observable,
            countdown: observable,
            gifts: observable,
            roomsLimit: observable,
            countLimit: observable,
            inviteData: observable,
            liveEvent: observable,
            changedRoomStatusName: observable,
            showWalletPopup: observable,
            showLoading: observable,
            renamedRoom: observable,
            changedStatusRoomData: observable,
            openProfile: observable,
            showSidebar: observable,
            showLanguagePage: observable,
            showPrivacyPolicy: observable,
            showProfileInfo: observable,
            showSocials: observable,
            openChooseAvatarType: observable,
            avatarCreatorPopup: observable,
            showMyAssets: observable,
            returnAsset: observable,
            dashboardBanners: observable,
            showCalendar: observable,
            activeMenu: observable,
            eventRooms: observable,
            qualityPopup: observable,
            quality: observable,
            copiaTab: observable,
            aiChatBotModal: observable,
            garage: observable,
            shops: observable,
            games: observable,
            setRooms: action.bound,
            setSpaces: action.bound,
            setMyEventsActive: action.bound,
            setMySpacesActive: action.bound,
            filterDraft: action.bound,
            resetFilterDraft: action.bound,
            setIsLiked: action.bound,
            setSortType: action.bound,
            setSearchValue: action.bound,
            setCountdown: action.bound,
            setGifts: action.bound,
            setRoomsLimit: action.bound,
            setCountLimit: action.bound,
            setClearCountLimit: action.bound,
            setHeaderWidth: action.bound,
            setIsRefreshedBoard: action.bound,
            setLikedRoom: action.bound,
            setOnlineUsersCount: action.bound,
            setInviteData: action.bound,
            clearInviteData: action.bound,
            endCountDown: action.bound,
            updateEventRoomData: action.bound,
            setShowLoading: action.bound,
            setRenamedRoom: action.bound,
            renameCurrentRoom: action.bound,
            setChangedStatusRoomData: action.bound,
            changeRoomStatus: action.bound,
            setShowWalletPopup: action.bound,
            setOpenProfile: action.bound,
            setShowLanguagePage: action.bound,
            setShowPrivacyPolicy: action.bound,
            setShowProfileInfo: action.bound,
            setShowSocials: action.bound,
            setOpenChooseAvatarType: action.bound,
            setAvatarCreatorPopup: action.bound,
            setShowMyAssets: action.bound,
            setReturnAsset: action.bound,
            setChangedRoomStatusName: action.bound,
            setDashboardBanners: action.bound,
            setShowCalendar: action.bound,
            setEventRooms: action.bound,
            setQualityPopup: action.bound,
            setQuality: action.bound,
            setCopiaTab: action.bound,
            setAddChatBotAi: action.bound,
            setGarage: action.bound,
            setShops: action.bound,
            setGames: action.bound,
            getActiveMenu: computed,
        });
    }

    setRooms(data) {
        this.rooms = [...data];
    }

    setAddChatBotAi() {
        this.aiChatBotModal = !this.aiChatBotModal;
    }

    setEventRooms(data) {
        this.eventRooms = [...data];
    }

    setSpaces(data) {
        this.spaces = [...data];
    }

    setMyEventsActive(string) {
        cacheService.set("activeMenu", string)
        this.myEventsActive = string;
    }

    setMySpacesActive(flag) {
        this.mySpacesActive = flag;
    }

    filterDraft() {
        this.filteredRooms = this.rooms.filter((room) => room?.room_state === 2);
    }

    resetFilterDraft() {
        this.filteredRooms = null;
    }

    setIsLiked(roomId, {liked, total_count}) {
        this.rooms.map((room) => {
            if (Number(room.public_id) === Number(roomId)) {
                room.is_liked = liked;
                room.likes_total_count = total_count;
            }
            return room;
        });
        this.eventRooms.map(( room ) => {
            if (Number(room.room.public_id) === Number(roomId)) {
                room.room.is_liked = liked;
                room.room.likes_total_count = total_count;
            }
            return room;
        });
        unityStore.setIsLiked(liked);
        unityStore.setTotalLikes(total_count);
    }

    setSortType(string) {
        this.sortType = string;
    }

    setSearchValue(string) {
        this.searchValue = string;
    }

    setCountdown(obj) {
        this.countdown = obj;
    }

    setGifts(arr) {
        this.gifts = arr;
    }

    setRoomsLimit(number) {
        this.roomsLimit = number;
    }

    setCountLimit(number) {
        this.countLimit = number;
    }

    setClearCountLimit() {
        this.countLimit = this.limit;
    }

    setHeaderWidth(num) {
        this.headerWidth = num;
    }

    setIsRefreshedBoard(bool) {
        this.isRefreshedBoard = bool;
    }

    setInviteData(data) {
        this.inviteData = data;
    }

    clearInviteData() {
        this.inviteData = {};
    }

    setLikedRoom({room_id, likes_total_count}) {
        this.rooms.map((room) => {
            if (Number(room_id) === Number(room.public_id)) {
                room.likes_total_count = likes_total_count;
            }
            return room;
        });
        this.eventRooms.map(( room ) => {
            if (Number(room_id) === Number(room.room.public_id)) {
                room.room.likes_total_count = likes_total_count;
            }
            return room;
        });
        unityStore.setTotalLikes(likes_total_count);
    }

    setOnlineUsersCount(data) {
        this.rooms.map((room) => {
            if (Number(data?.room_id) === Number(room.public_id)) {
                room.online_users_count = data?.online_users_count;
            }
            return room;
        });
    }

    renameCurrentRoom(room_id, name) {
        this.rooms.map((room) => {
            if (Number(room_id) === Number(room.public_id)) {
                room.room_name = name;
            }
            return room;
        });
        this.eventRooms.map(( room ) => {
            if (Number(room_id) === Number(room.room.public_id)) {
                room.room.room_name = name;
            }
            return room;
        });
    }

    changeRoomStatus(roomId, status) {
        this.rooms.map((room) => {
            if (Number(roomId) === Number(room.public_id)) {
                room.room_state = status;
            }
            return room;
        });
        this.eventRooms.map(( room ) => {
            if (Number(roomId) === Number(room.room.public_id)) {
                room.room.room_state = status;
            }
            return room;
        });
    }

    endCountDown(data) {
        this.rooms = this.rooms.map((room) => {
            if (room.public_id === data.public_id) {
                room = {...room, ...data};
            }
            return room;
        });
        this.eventRooms = this.eventRooms.map((room) => {
            if (room.room.public_id === data.public_id) {
                room = {
                    ...room,
                    room: {
                        ...room.room,
                        ...data
                    }
                };
            }
            return room;
        });
    }

    updateEventRoomData(event_room_public_id, valid, time) {
        this.rooms = this.rooms.map((room) => {
            if (room.public_id === event_room_public_id) {
                room = {
                    ...room,
                    event_data: {
                        event_member: valid,
                        event_minutes: time,
                    },
                };
            }
            return room;
        });
        this.eventRooms = this.eventRooms.map((room) => {
            if (room.room.public_id === event_room_public_id) {
                room = {
                    ...room,
                    room: {
                        ...room.room,
                        event_data: {
                            event_member: valid,
                            event_minutes: time,
                        },
                    }
                };
            }
            return room;
        });
    }

    setShowLoading(bool) {
        this.showLoading = bool;
    }

    setRenamedRoom(data) {
        this.renamedRoom = data;
    }

    setChangedStatusRoomData(data) {
        this.changedStatusRoomData = data;
    }

    setShowWalletPopup(bool) {
        this.showWalletPopup = bool;
    }

    setOpenProfile(bool) {
        this.openProfile = bool;
    }

    setShowLanguagePage(bool) {
        this.showLanguagePage = bool;
        this.showSidebar = !bool;
    }

    setShowPrivacyPolicy(bool) {
        this.showPrivacyPolicy = bool;
        this.showSidebar = !bool;
    }

    setShowProfileInfo(bool) {
        this.showProfileInfo = bool;
        this.showSidebar = !bool;
    }

    setShowSocials(bool) {
        this.showSocials = bool;
        this.showSidebar = !bool;
    }

    setOpenChooseAvatarType(bool) {
        this.openChooseAvatarType = bool;
        // this.showSidebar = !bool;
    }

    setAvatarCreatorPopup(str) {
        this.avatarCreatorPopup = str;
        this.openChooseAvatarType = false;
    }

    setShowMyAssets(bool) {
        this.showMyAssets = bool;
    }

    setReturnAsset(data) {
        this.returnAsset = data;
    }

    setChangedRoomStatusName(bool) {
        this.changedRoomStatusName = bool;
    }

    setDashboardBanners(arr) {
        this.dashboardBanners = arr;
    }

    setShowCalendar(bool) {
        this.showCalendar = bool;
    }

    setQualityPopup(bool) {
        this.qualityPopup = bool;
    }

    setQuality(num) {
        cacheService.set("quality", `${num}`);
        this.quality = num;
    }

    setCopiaTab(str) {
        this.copiaTab = str;
    }

    setGarage(arr) {
        this.garage = arr;
    }

    setShops(arr) {
        this.shops = arr;
    }

    setGames(arr) {
        this.games = arr;
    }

    get getActiveMenu() {
        const activeMenu = cacheService.get("activeMenu");
        const result = this.myEventsActive || activeMenu || "-fastex"; 
        return result
    }
}

export const dashboardStore = new DashboardStore();
