import {observer} from "mobx-react-lite";

import {tr} from "../../../helpers/translations/tr";
import { useNavigateTo } from '../../../helpers/navigateTo'

import { authStore } from '../../../store/authStore'

import apiService from "../../../services/apiServices";

import BlurredBackgroundPopup from "../PopupsWrappers/BlurredBackgroundPopup";

import styles from "../popups.module.css";

const IncompleteVerificationPopup = observer(() => {
    const {email, setShowVerifyPopup} = authStore;

    const {navigateTo} = useNavigateTo();

    const cancelAction = () => {
        setShowVerifyPopup("");
    }

    const setVerifyUser = () => apiService.setUserEmailVerify({
        email
    });

    const verifyAction = async () => {
        try {
            await setVerifyUser();
            navigateTo("/signup/verify_code")
            cancelAction();
        } catch (e) {
            console.warn("ERROR: ", e.message);
        }
    }

    return (
        <BlurredBackgroundPopup revert={true} action={cancelAction} className={styles.popupSize}>
            <h2 className={styles.popupTitle}>{tr("incomplete_verification_title")}</h2>
            <p className={styles.popupDescription}>{tr("incomplete_verification_desc")}</p>
            <div className={styles.popupButtonsContainer}>
                <button onClick={cancelAction} className={styles.popupButton}>{tr("cancel")}</button>
                <button onClick={verifyAction}
                        className={`${styles.popupButton} ${styles.magenta}`}>{tr("verify")}</button>
            </div>
        </BlurredBackgroundPopup>
    )
})

export default IncompleteVerificationPopup;