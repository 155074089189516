import React, {useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {nftStore} from "../../../store/nftStore";
import { translationStore } from "../../../store/translationStore";
import {cacheService} from "../../../services/cacheService";
import { useNftData, useNftTransaction } from "../../../hooks/useNftAPI";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {tr} from "../../../helpers/translations/tr";
import InfoContainer from "./InfoContainer/InfoContainer";
import spiner from "../../../assets/images/spiner.gif";
import {ReactComponent as Sale} from "../../../assets/icons/sale.svg";
// import defaultImg from "../../../ui/assets/cerebrum_default.png"
import styles from "./index.module.scss";
import { useAccount } from "wagmi";
import { dashboardStore } from "../../../store/dashboardStore";
import { notificationStore } from '../../../store/notificationStore'

const CerebrumNftNew = observer(() => {
    const [tab, setTab]= useState("tab1");
    const { setShowWalletPopup } = dashboardStore;
    const {address, isConnected} = useAccount();
    const ref = useRef();
    const imgRef = useRef();
    const {setSelectedCerebrumNft, clearNftData} = nftStore;
    const {setNotification} = notificationStore;

    const {owner, price, description, status, image, imageLoaded, setImageLoaded, salePrice, name} = useNftData()
    
    const { buyTransaction, walletConnectBuy, account, pending } = useNftTransaction();

    const buyTransactionHandler = ()=> {
        setNotification({
            type: "error",
            text: tr("unavailable_action")
        })
        // if(!window.ethereum && !isConnected){
        //     return setShowWalletPopup(true);
        // }
        // !window.ethereum ? walletConnectBuy() : buyTransaction()
    }
    
    const {translationData} = translationStore;

    const closePopup = () => {
        setSelectedCerebrumNft(0);
        clearNftData()
    };

    useOnClickOutside(ref, () => closePopup());

    return (
        <div ref={ref} className={styles.buy_nft_inner_container}>
            <div className={styles.buy_nft_outer_container}>
                <i className={styles.icon_close} onClick={closePopup}/>
                <div className={`${styles.buy_nft_img_container} ${status === "2" ? styles.sold : ""}`}>
                    {!imageLoaded ? <span className={styles.loadImage}/> : <></>}
                    <img ref={imgRef} alt="nft" className={styles.buy_nft_img} src={image} onLoad={() => setImageLoaded(true)}
                    //      onError={() => {
                    //     imgRef.current.src = defaultImg;
                    //     setImageLoaded(true);
                    // }}
                    />
                    {(owner && owner === account) ? <h1>Is My NFT</h1> : <></>}
                </div>
                <div className={styles.buy_nft_desc_container}>
                    <div className={styles.nft_info}>
                        <div className={styles.scrol_content}>
                            <div className={styles.description}>
                                <p className={styles.name}>{name}</p>
                            </div>
                            <div className={styles.description}>
                                <p className={styles.description_title}>{translationData?.['description'] || 'Description'}</p>
                                <p>{description}</p>
                            </div>
                            <div className={styles.tabs_container}>
                                {/*<div className={`${styles.blockchain_btn} ${tab === 'tab1' ? styles.selected : ''}`}*/}
                                {/*    onClick={() => setTab('tab1')}>{translationData?.['blockchain'] || 'Blockchain'}</div>*/}
                                {/*<div className={`${styles.transaction_history} ${tab === 'tab2' ? styles.selected : ''}`}*/}
                                {/*    onClick={() => setTab('tab2')}>{translationData?.['transaction_history'] || 'Transaction history'}</div>*/}
                            </div>
                            <InfoContainer
                                tab={tab}
                            />
                        </div>
                    </div>
                    {Number(price) !== 0 && status === "1" ?
                        <div className={styles.buy_nft_discount}><Sale/>{price} FTN</div> : <></>}
                    <button
                        disabled={status !== "1" || pending || cacheService.isGuest}
                        className={styles.buy_nft_btn}
                        type="button"
                        onClick={buyTransactionHandler}
                    >
                        {price === "" || pending ? <img alt={"load"} src={spiner} className={styles.spinner}/> :
                            status === "1" ? `${translationData?.["buy"]} ${salePrice} FTN` :
                                status === "2" && owner === account ? "Purchased" :
                                    status === "2" ? translationData?.["sold"] || "SOLD" :
                                        translationData?.["out_of_stock"]
                        }
                        {pending && <span>Please Confirm In Your Wallet</span>}
                        {status === "1" ? <i className={styles.icon_discount}/> : <></>}
                        {cacheService.isGuest && <span>{tr('guest_cannot_purchase')}</span>}
                    </button>
                </div>
            </div>
        </div>
    );
});

export default CerebrumNftNew;