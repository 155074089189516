import style from "./svgJs.module.scss";

const BackBtnSvg = ({action = () => {}, className}) => {
    return (
        <svg onClick={action} className={`${style.backBtn} ${className}`} width="2.4rem" height="2.4rem" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6666 16H5.33325M5.33325 16L13.3333 8M5.33325 16L13.3333 24" className={style.stroke} strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default BackBtnSvg;