import {useLocation} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import gsap from "gsap";
import {isMobile} from "react-device-detect";

import styles from "./customswitch.module.css"

TopBarProgress.config({
    barColors: {
        "0": "#A60063",
        "0.5": "#b70065",
        "1.0": "#600035",
    },
    shadowBlur: 10
});
const CustomSwitch = ({ children }) => {
    const [progress, setProgress] = useState(false);
    let containerRef = useRef();
    const location = useLocation();

    useEffect(() => {
        setProgress(true);

        const timer = setTimeout(() => {
            setProgress(false);
        }, 400); // Adjust the duration as needed

        return () => {
            clearTimeout(timer);
        };
    }, [location.pathname]);

    useEffect(() => {
        const timeline = gsap.timeline();
        timeline.from(containerRef, {
            duration: 0.35,
            opacity: 0,
            // ease: "power2.out",
        }).to(containerRef, {
            opacity: 1,
            ease: "power2.out",
        });

        return () => {
            timeline.kill();
        };
    }, [location]);

    return (
        <>
            <div className={`${styles.customswitch} ${isMobile ? styles.revert : ""}`} ref={el => containerRef = el}>
                {children}
            </div>
            {progress && <TopBarProgress />}
        </>
    )
}

export default CustomSwitch;