import { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { errorStore } from '../../store/errorStore'
import { ReactComponent as ConnectionLost } from '../../assets/icons/connection-lost.svg'
import { ReactComponent as InternetConnected } from '../../assets/icons/internet-connected.svg'
import { translationStore } from '../../store/translationStore'
import WarningPopup from './WarningPopup'
import {cacheService} from "../../services/cacheService";
import { participantsStore } from '../../store/participantsStore'
import './index.scss'
import BlurredBackgroundPopup from '../../ui/Popups/PopupsWrappers/BlurredBackgroundPopup'
import styles from '../../ui/Popups/popups.module.css'
import { tr } from '../../helpers/translations/tr'
import { useNavigateTo } from '../../helpers/navigateTo'

const ErrorModal = observer(() => {
    const { closeModal, hasOnModal, modalMessage, closeable, modalTitle, connected, actionBtnOk, errorOptions } = errorStore;
    const {setBlockedUser} = participantsStore;
    const [timer, setTimer] = useState({});

    const timeObjectToSeconds = useCallback( ({ hours = 0, minutes = 0, seconds = 0 }) =>
    { return hours * 3600 + minutes * 60 + seconds || 0}, [errorOptions?.options]);

    let totalSeconds = timeObjectToSeconds(errorOptions?.options || {})

    const secondsToTimeObject = totalSeconds => {
        if(totalSeconds <= 0) return 
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return { hours, minutes, seconds };
    };

    let timerId = null

    let timerHandler = ()=> {
        if(totalSeconds > 1) { 
            setTimer(secondsToTimeObject(--totalSeconds));
        }
        else{ 
            clearInterval(timerId);
            errorOptions?.options?.seconds && onCloseModal()
        }
    }

    useEffect(()=> {
        if(errorOptions?.options) {
            timerId = setInterval(timerHandler, 1000);
        }

        return ()=> {
            clearInterval(timerId);
        }
    }, [errorOptions])

    const { translationData } = translationStore;

    const { navigateTo } = useNavigateTo()

    const onAcceptBtnAction = (index) => {
        if(errorOptions?.actionBtnNavigate) {
            errorOptions.actionBtnNavigate();
            navigateTo(errorOptions?.actionBtnLink[index])
        }
        closeModal();
    }

    const onCloseModal = () => {
        cacheService.remove(["AlternateGuest"])
        setBlockedUser(false);
        closeModal();
    }

    return (
        <>
            {hasOnModal && (
                <BlurredBackgroundPopup revert={true} className={!closeable ? styles.highZIndex : ''} action={onCloseModal}>
                    <h2 className={styles.popupTitle}>{tr(modalTitle) || modalTitle}</h2>
                    <p className={styles.popupDescription}>{tr('exit')}</p>
                    <p className={styles.popupDescription}>{tr(modalMessage)}</p>
                    {
                        Object.keys(timer).map((item, i) => {
                            if (timer[item] > 0) return <span
                                key={i}>{` ${timer[item]}`} {translationData?.[item]}</span>

                        })
                    }
                    {connected === undefined ? null : connected ? (
                        <InternetConnected />
                    ) : (
                        <ConnectionLost className="connection_lost" />
                    )}
                    <div className={styles.popupButtonsContainer}>
                        {actionBtnOk && errorOptions?.actionBtnName ? errorOptions?.actionBtnName.map((actionBtn, index) => (
                            <button key={index} className={`${styles.popupButton} ${errorOptions?.actionBtnColor[index] === "magenta" ? styles.magenta : ""}`} onClick={() => onAcceptBtnAction(index)}>
                                {tr(actionBtn || 'ok')}
                            </button>
                        )) : <button className={`${styles.popupButton} ${styles.fullWidth}`} onClick={onAcceptBtnAction}>
                            {tr("ok")}
                        </button>}
                    </div>
                </BlurredBackgroundPopup>
            )
            }
            <WarningPopup />
        </>
    )
})

export default ErrorModal
