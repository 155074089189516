import QRCode from "qrcode.react";
import { observer } from 'mobx-react-lite'
import BlurredBackgroundPopup from "../../../../../../ui/Popups/PopupsWrappers/BlurredBackgroundPopup";
import { buyCryptoStore } from "../../../../../../store/buyCryptoStore";
import QrEmpty from "../../../../../../assets/icons/Qr-Code.gif";
import CustomInput from "../../../../../../ui/CustomInput";
import { ReactComponent as CopyAddress } from "../../../../../../assets/icons/copyAddress.svg";
import styles from "./styles.module.scss";
import { keyboardLockStore } from "../../../../../../store/keyboardLockStore";

export const BuyCrypto = observer(()=> {
    const { setShowQrAddress, onChangeAmount, getFulgurData, copyAddresshandler, address, loading, amount, cryptoAmount } = buyCryptoStore;
    const {setKeyboardLocked} = keyboardLockStore;
    
    const changeAmountHandler = (e)=> {
        onChangeAmount(e)
    }

    const blurHandler = ()=> {
        setKeyboardLocked(true)
    }

    const focusHandler = ()=> {
        setKeyboardLocked(false)
    }
    
    return (
        <BlurredBackgroundPopup action={()=>setShowQrAddress(false)}>
            <div className={styles.BuyCrypto}>
                <div className={styles.content}>
                    <div className={styles.title}>Account Balance</div>
                    <div className={styles.description}>Enter your account info to deposit</div>
                    <div className={`${styles.qr_code} ${address  || loading ? styles.visible : ""}`}>
                        {
                            address ? (
                                <QRCode className={styles.qr_code_img} value={address}/>
                            ): (
                                <img src={QrEmpty} className={styles.qr_empty}/>
                            )
                        }
                    </div>
                    <span className={styles.info}>{address? "Scan QR Code" : "Type Amount and Get QR Code"}</span>
                    <div className={styles.input_container}>
                        <div>
                            <span className={styles.amount}>Amount</span>
                            <div className={styles.amount_container}>
                                <CustomInput type="number" onBlur={blurHandler} onFocus={focusHandler} className={styles.wallet_amount} disabled={!!cryptoAmount} value={cryptoAmount || amount} placeholder='10.00000000' setValue={changeAmountHandler}/>
                            </div>
                        </div>
                        {
                            address && (
                                <div>
                                    <span className={styles.one_time_address}>One-time wallet address</span>
                                    <div className={styles.address_container}>
                                        <CustomInput className={styles.wallet_addres} value={address} disabled/>
                                        <CopyAddress onClick={copyAddresshandler} className={styles.copy_address}/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.footer_content}>
                        <div onClick={getFulgurData} className={`${styles.get_btn} ${!amount || address ? styles.disabled : ""}`}>
                            <span className={styles.editAvatar}>Get Wallet Address</span>
                        </div>
                    </div>
                </div>
            </div>
        </BlurredBackgroundPopup>
    )
})