import { makeAutoObservable } from 'mobx';
import apiService from '../services/apiServices';
import { copyTextToClipboard } from '../utils';
import { notificationStore } from './notificationStore';
const {setNotification} = notificationStore;


class BuyCryptoStore {
  address = '';
  showQrCode = false;
  loading = false;
  amount = '';
  userbalance = 0;
  cryptoAmount = '';

  setUserBalance = (val) => {
    this.userbalance = val
  }

  setShowQrAddress = (bool) =>{
    this.showQrCode = bool;
    if(!bool){
      this.address = '';
      this.showQrCode = false;
      this.loading = false;
      this.amount= 0;
      this.cryptoAmount= 0;
    }
  }

  setAddress = (address) =>{
    this.address = address;
  }

  getFulgurData = async() =>{
    if(!this.amount || this.address) return 
    try {
      this.loading = true
      const {data: {data:{address, crypto_amount}}} = await apiService.getFulgurData({
        "amount": this.amount
      })
      this.loading = false
      this.address = address;
      this.cryptoAmount = Number(crypto_amount).toFixed(2)
    } catch (error) {
      console.log(error, "error");
      this.loading = false
    }
  }

  onChangeAmount = (val) =>{
    this.amount = val;
  }

  copyAddresshandler = async() =>{
    await copyTextToClipboard(this.address)
    setNotification({type:'success'});
  }
  
  constructor() {
    makeAutoObservable(this);
  }
}

export const buyCryptoStore = new BuyCryptoStore();


