import {lazy} from "react";
import {Hoc} from "../hoc/Auth";

const SignIn = lazy(() => import("../ui/SignIn"));
const SignUp = lazy(() => import("../ui/SignUp"));
const VerifyCode = lazy(() => import("../ui/VerifyCode"));
const ForgotPasswordEmail = lazy(() => import("../ui/ForgotPassword/Email"));
const ForgotPasswordVerifyCode = lazy(() => import("../ui/ForgotPassword/VerifyCode"));
const MaintenancePage = lazy(() => import("../ui/MaintenancePage"));
const NotFoundPage = lazy(() => import("../ui/NotFoundPage"));
const ExpiredPage = lazy(() => import("../ui/ExpiredPage"));
const ForgotCreatePassword = lazy(() => import("../ui/ForgotPassword/CreatePassword"));
const Guest = lazy(() => import("../ui/Guest"));
const AppleEmail = lazy(() => import("../ui/AppleAuth/Email"));
const AppleVerifyCode = lazy(() => import("../ui/AppleAuth/VerifyCode"));
const Dashboard = lazy(() => import("../containers/dashboard"));
const FastexComp = lazy(() => import("../components/fastexComp"));
const ForgotPassword = lazy(() => import("../containers/newForgot"));
const RedirectContent = lazy(() => import("../containers/redirectContent"));
const Room = lazy(() => import("../containers/room"));
const DreamPackage = lazy(() => import("../containers/dreamPackage"));
const InviteContent = lazy(() => import("../containers/inviteContent"));
const HomeSpace = lazy(() => import("../containers/homeSpace"));
const BuyNft = lazy(() => import("../containers/buyNft"));
const TwitchComp = lazy(() => import("../components/TwitchComp"));

const routes = [
    {
        path: "",
        component: SignIn,
        title: "Login",
    },
    {
        path: "signup",
        component: SignUp,
        title: "SignUp",
    },
    {
        path: "signup/verify_code",
        component: VerifyCode,
        title: "VerifyCode",
    },
    {
        path: "forgot/verify",
        component: ForgotPasswordVerifyCode,
        title: "ForgotPasswordVerifyCode",
    },
    {
        path: "forgot",
        component: ForgotPasswordEmail,
        title: "ForgotPasswordEmail",
    },
    {
        path: "apple/email",
        component: AppleEmail,
        title: "AppleEmail",
    },
    {
        path: "apple/code",
        component: AppleVerifyCode,
        title: "AppleVerifyCode",
    },
    {
        path: "forgot/pass",
        component: ForgotCreatePassword,
        title: "CreatePasswordWr",
    },
    {
        path: "dashboard",
        component: Hoc(Dashboard),
        title: "Dashboard",
    },
    {
        path: "dashboard/:roomId",
        component: Hoc(Room),
        title: "Project",
    },
    {
        path: "expired-link",
        component: ExpiredPage,
        title: "Expired Link"
    },
    {
        path: "guest/:roomId",
        component: Guest,
        title: "Guest"
    },
    {
        path: "fastex",
        component: FastexComp,
        title: "FastexAuth"
    },
    {
        path: "twitch",
        component: TwitchComp,
        title: "TwitchAuth"
    },
    {
        path: "404",
        component: NotFoundPage,
        title: "404",
    },
    {
        path: "maintenance",
        component: MaintenancePage,
        title: "Maintenance"
    },
    {
        path: "set_new_password/:token",
        component: ForgotPassword,
        title: "Forgot Password"
    },
    {
        path: "room/:roomId",
        component: RedirectContent,
        title: "Partners Redirect"
    },
    {
        path: "dream-package/:roomId",
        component: DreamPackage,
        title: "Dream Package Redirect"
    },
    {
        path: "invite-user-redirect/:roomId",
        component: InviteContent,
        title: " Invite Content"
    },
    {
        path: "home_space",
        component: HomeSpace,
        title: "HomeSpace",
    },
    {
        path: "connect_nft",
        component: BuyNft,
        title: "HomeSpace",
    },
];

export default routes;
