import {useState} from "react";
import PopupContainer from "../PopupContainer";
import styles from "./blurredBackgroundPopup.module.css";

const BlurredBackgroundPopup = ({children, revertColor = false, action, className = "", revert, closable = true}) => {
    const [showPopup, setShowPopup] = useState(true);
    const onClose = () => {
        if(closable) {
            setShowPopup(false)
            setTimeout(() => {
                action()
            }, 200)
        }
    }
    return (
        <div
            className={`${styles.blurredBackgroundPopup} ${!showPopup ? styles.closed : ""} ${revertColor ? styles.revertColors : ""}`}>
            <PopupContainer action={onClose} revert={revert} className={className} closable={closable}>
                {children}
            </PopupContainer>
        </div>
    )
}

export default BlurredBackgroundPopup;