import {makeObservable, observable, action, computed} from "mobx";
import { ERROR_MAP, WARNING_OPTIONS, modifyErrorData } from "./constatns";

class ErrorStore {
  errors = {};
  errorOptions = {
    warning: true,
  };
  redirectMap = ERROR_MAP;
  warningMap = [];
  constructor() {
    makeObservable(this, {
      errors: observable,
      errorOptions: observable,
      warningMap: observable,
      actionBtnOk: computed,
      hasOnModal: computed,
      modalTitle: computed,
      closeable: computed,
      connected: computed,
      modalMessage: computed,
      setError: action.bound,
      reconnect_internet: action.bound,
      closeModal: action.bound,
      getErrorOptions: action.bound,
      setWarning: action.bound,
      removeWarning: action.bound,
      clearErrors: action.bound,
    });
  }

  getErrorOptions(errorKey) {
    return this.redirectMap[errorKey];
  }

  get actionBtnOk() {
    return this.errorOptions?.actionBtnOk;
  }

  get hasOnModal() {
    return !!this.errorOptions?.isModal;
  }

  get closeable() {
    return !!this.errorOptions?.closeable;
  }

  get modalTitle() {
    return this.errorOptions?.modal_title;
  }

  get modalMessage() {
    return this.errorOptions?.errorKey;
  }

  get connected() {
    return this.errorOptions?.connected;
  }

  closeModal() {
    if (this.closeable)
      this.errorOptions = { ...this.errorOptions, isModal: false };
  }

  reconnect_internet() {
    this.errorOptions = {
      ...this.errorOptions,
      modal_title: "Connection restored!",
      connected: true,
      errorKey: "reconnected",
    };

    setTimeout(() => {
      this.errorOptions = null;
    }, 1500);
  }

  setError(options, status = true, autoRemove = true) {
    if (!status) {
      // this.errors = delete this.errors[options.key];
      this.errors = { ...this.errors, [options.key]: status };
      this.errorOptions = { isModal: false };
      return this.errors;
    }

    this.errors = { ...this.errors, [options.key]: status };
    const errorOptions = this.getErrorOptions(options.key);
    if (errorOptions) {
      this.errorOptions = { ...errorOptions, errorKey: options.key, options: modifyErrorData(options) };
      if (errorOptions.callBack) errorOptions.callBack();
    } else {
      if(autoRemove) {
        setTimeout(() => this.setError({key: options.key}, false), 3000);
      }
    }
  }

  setWarning(warningKey) {
    this.warningMap.push({ ...WARNING_OPTIONS[warningKey], key: warningKey });
    setTimeout(() => this.removeWarning(warningKey), 3500);
  }

  removeWarning(warningKey) {
    this.warningMap = this.warningMap.filter(
      (error) => error.key !== warningKey
    );
  }

  clearErrors() {
    this.errors = {};
  }
}

export const errorStore = new ErrorStore();
