import React, {useRef} from 'react'
import {observer} from 'mobx-react-lite'

import {tr} from "../../../helpers/translations/tr";

import {roomStore} from '../../../store/roomStore'

import useOnClickOutside from '../../../hooks/useOnClickOutside'

import {cacheService} from '../../../services/cacheService'

import BlurredBackgroundPopup from "../PopupsWrappers/BlurredBackgroundPopup";

import styles from "../popups.module.css";
import {clearCache} from "../../../helpers/clearCache";

const GuestPopup = observer(() => {
    const {setShowGuestSignInPopup} = roomStore
    const ref = useRef()
    const closePopup = () => {
        setShowGuestSignInPopup(false)
    }
    useOnClickOutside(ref, closePopup)

    const pressToSignIn = () => {
        const alternateGuest = cacheService.get('AlternateGuest')
        clearCache();
        cacheService.set('AlternateGuest', alternateGuest)
        window.location.assign('/')
    }

    return (
        <BlurredBackgroundPopup revert={true} action={closePopup}>
            <h2 className={styles.popupTitle}>{tr("want_to_continue")}</h2>
            <p className={styles.popupDescription}>{(tr("please_sign_in_participate_game"))}</p>
            <div className={styles.popupButtonsContainer}>
                <button onClick={closePopup} className={styles.popupButton}>{tr("cancel")}</button>
                <button onClick={pressToSignIn}
                        className={`${styles.popupButton} ${styles.magenta}`}>{tr("sign_in")}/{tr("sign_up")}</button>
            </div>
        </BlurredBackgroundPopup>
    )
})

export default GuestPopup