import { createContext } from "react";
import { useRoutionAuth } from "./useRoutionAuth";

export const DecoratorForRoutionAuthContext = createContext();

const DecoratorForRoutionAuth = ({children})=> {
    const state = useRoutionAuth();

    return (
        <DecoratorForRoutionAuthContext.Provider value={{...state}}>
           {children}
        </DecoratorForRoutionAuthContext.Provider>
    )
}

export default DecoratorForRoutionAuth