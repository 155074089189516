import style from './svgJs.module.scss'

const PrevBtnSvg = ({ action = () => {}, onlyWhite, className}) => {
    return (
        <svg onClick={action} className={`${style.navBtn} ${className}`} width="3.6rem" height="3.6rem" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.53709 10C5.32093 10 5.12879 9.92795 4.98469 9.78384L1.14193 5.94108C0.829704 5.65287 0.829704 5.14851 1.14193 4.8603L4.98469 1.01754C5.2729 0.705318 5.77726 0.705318 6.06547 1.01754C6.37769 1.30575 6.37769 1.81011 6.06547 2.09832L2.7751 5.38868L6.06547 8.70307C6.37769 8.99127 6.37769 9.49564 6.06547 9.78384C5.92136 9.92795 5.72923 10 5.53709 10Z"
                className={onlyWhite ? style.onlyWhiteFill : style.fill} />
        </svg>
)
}

export default PrevBtnSvg